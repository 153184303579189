import React from "react"
import { colors, viewports } from "../../theme/vars"

const HeroStyles = () => (
  <style jsx global>{`
    .hero {
      position: relative;
      padding: 40px;
      background: ${colors.secondary};
      width: 100%;
      border-radius: 0 0 50px 50px;
      z-index: 5;
      margin-top: -9%:
    }

    .hero__image {
      z-index: 0;
      background: ${colors.secondary};
      top: 0
    }

    .hero .ant-col {
      z-index: 12;
    }

    .hero h1.ant-typography {
      margin-top: 50px;
      text-align: center !important;
    }

    .hero__title {
      opacity: 1;
    }

    .hero__ctas-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .hero__cta-secondary {
      margin-top: 16px;
    }
    .hero__cta {
      max-width: 140px;
    }

    @media ${viewports.mdOrBigger} {
      .hero__ctas-wrapper {
        width: 100%;
        flex-direction: row;
        margin-top: 30px;
        align-items: start;
      }

      .hero__cta {
        margin-top: 16px;
        min-width: 180px;
      }

      .hero__cta-secondary {
        margin-top: 6px;
        margin-left: 16px;
        min-width: 180px;
      }
    }

    .hero__info {
      color: ${colors.textInverse};
      font-size: 18px;
      font-weight: 500;
      padding-right: 35%;
      padding-bottom: 50px;
      opacity: 0;
      transform: translateX(-50px);
      transition: all 1s ease-out;
    }

    .hero__info.animate {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0.3s;
    }

    @media ${viewports.mdOrSmaller} {
      .hero {
        padding-top: 30px;
        text-align: center;
      }
      .hero__image {
        margin-top: 80px;
      }
      .hero__info {
        padding-right: 0;
        text-align: center;
      }
      .hero__cta{
        font-size: 16px !important;
      }
    }

    @media ${viewports.xsOnly} {
      .hero__cta,
      .hero__cta-secondary {
        width: 100%;
      }
    }

    .hero__cta {
      margin-top: 6px;
    }

    .hero__current-rates-wrapper {
      display: none;
    }

    .hero__current-rates {
      padding: 25px 45px;
      border-radius: 30px;
      background: linear-gradient(180deg, #fff 70%, #c4c4c4);
      box-shadow: 0px 0px 15px 5px rgb(0 0 0 / 23%);
    }

    .hero__current-rates--title {
      font-family: "Inter";
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 4px;
      color: ${colors.primary};
    }

    .hero__current-rates--item {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      padding: 4px 0;
      color: ${colors.textSecondary};
    }

    @media ${viewports.lgOrBigger} {
      .hero{
        margin-top: -9%;
      }

      .hero__current-rates-wrapper {
        position: fixed;
        bottom: 0;
        right: 80px;
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 24px;
      }

      .hero h1.ant-typography {
        text-align: left !important;
      }

      .hero__current-rates {
        border-radius: 30px 30px 0 0;
      }

      .hero__content-wrapper {
        padding-top: 60px;
        align-items: flex-start;
        padding-left: 55px;
      }
    }
  `}</style>
)

export default HeroStyles
