import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated as a } from 'react-spring';

import chevron from '../../images/chevron.svg';


const Expander = (props) => {
  const { title, description, expanded, clickHandler, index } = props;

  return (
    <a.div
      style={useSpring({
        padding: expanded ? '25px 25px' : '0px 25px',
      })}
      className="eli"
      onClick={() => clickHandler(index)}
    >
      <div className="eli__info">
        <a.div className="eli__title">
          <span>{title}</span>
        </a.div>
        <a.div className="eli__description" style={ useSpring({
    opacity: expanded ? 1 : 0,
    color: '#7D95AF',
    fontSize: '14px',
    lineHeight: 2,
    // fontFamily: 'Lato, sans-serif',
    fontWeight: 300,
    display: expanded ? 'block' : 'none',
  })}>
          {description}
        </a.div>
      </div>
      <a.div className="eli__toggle">
        <img
          className={expanded ? 'eli__upwards' : 'eli__down'}
          src={chevron}
          alt=""
        />
      </a.div>
    </a.div>
  );
};

Expander.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  expanded: PropTypes.bool,
  clickHandler: PropTypes.func,
  index: PropTypes.number,
  // sectionData: PropTypes.objectOf(PropTypes.any),
};
Expander.defaultProps = {
  title: '',
  description: '',
  expanded: false,
  clickHandler: null,
  index: null,
};

export default Expander;
