import React, { useState, useEffect, useContext } from "react"
import { Row, Col, Typography, Button } from "antd"
import HeroStyles from "./styles"
import { StaticImage } from "gatsby-plugin-image"

const { Title } = Typography

const Hero = (props) => {
  const {title, subtitle, copyParagraph} = props;
  return (
    <Row className="hero" justify="center" align="middle" style={{flexDirection: "row-reverse", verticalAlign: "center"}} >
      <Col xs={24} lg={14}>
        <StaticImage
          className="hero__image"
          src="../../images/BP_hero_main.png"
          alt="hero background"
          placeholder="none"
        />
      </Col>
      <Col xs={24} lg={10} className="hero__content-wrapper">
        <Title className="hero__title">
          {title}
        </Title>
        <p>
          {subtitle}
        </p>
        <p>
        {copyParagraph}
        </p>
      </Col>


      <HeroStyles />
    </Row>
  )
}

export default Hero
