import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import FAQ from "../components/FAQ/Faqs"

const restaurantFAQs = {
    title: "",
    ctaPrime: {
      ctaTitle: "",
      ctaTarget: "",
    },
    list: [
      {
        title: "What is BP App used for? ",
        body: "Bryan Post - The Parenting App is a provider of mobile parenting advice along with personal wellness. The app can be used for a range of things from parenting support to meditation and grounding techniques. In addition, the app also gives you 24/7 access to request the support of a coach at any time. The app offers free and paid for education parenting courses taught by Bryan Post himself",
      },
      {
        title: "Who is the founder of BP app? ",
        body: "Bryan Post, an adopted and former foster child, is one of America’s Foremost Child Behavior and Adoption Experts and founder of the Post Institute. A renowned clinician, lecturer, and best-selling author of From Fear to Love, The Great Behavior Breakdown and ten other books, and more than 100 video and audio programs, Bryan has traveled throughout the world providing expert treatment and consultation to a variety of groups. An internationally recognized specialist in the treatment of emotional and behavioral disturbance, Bryan specializes in a love-based treatment approach that focuses on developing a deeper understanding of trauma, stress and fear and how they rule our lives. He counters this by offering an enlightening perspective on the all-encompassing power of love to bring us peace and healing. The love-based, family-centered principles and concepts offered by Bryan have been taught to more than one million parents and professionals around the world. You can receive a free copy of his best-selling adoption parenting book From Fear to Love by going to https:///www.feartolovebook.com",
      },
      {
        title: "Can we request 101 coaching with Bryan? ",
        body: "Bryan is not currently taking on new appointments for 101 coaching however we have a team of our most trusted coaches, all personally trained by Bryan, waiting to support your family.",
      },
      {
        title: "How do we request 101 coaching? ",
        body: "You can request coaching by clicking on the “Bryan!” Tab on the app",
      },
      {
        title: "What forms of payment are accepted? ",
        body: "We accept VISA, Mastercard, Ethereum and Bitcoin as payment. ",
      },
      {
        title: "Does BP app replace a licensed therapist? ",
        body: "We are not a health care or medical device, nor should our products be considered medical advice. Bryan Post - The Parenting  App should never take the place of professional therapeutic interventions. This app is designed to promote healthy communication between a child and helping adults in their life. These activities may not work for every child as every child is different. Some children have more extreme behaviors than others. Bryan Post - The Parenting App makes no claims, representations or guarantees that the content or products provide a physical or therapeutic benefit.",
      },
      {
        title: "Does the app accept insurance? ",
        body: "BP app does not accept insurance as a form of payment.",
      },
      {
        title: "How do I cancel my subscription?  ",
        body: "In the event that you wish to cancel your subscription you can do so by clicking cancel with the App under the support tab. ",
      },
      {
        title: "What is the monthly cost of the subscription? ",
        body: "7 free day trial/ 4.99 monthly or 47.88 annually. ",
      },
    ],
  };

const FAQPage = () => {

  return (
    <Layout>
      <SEO title="Frequently Asked Questions" />
      <Hero title="Frequently Asked Questions" subtitle="Here you can find answers for frequently asked qustins"/>

      <section>
        <FAQ sectionData={restaurantFAQs}/>
      </section>

     

    </Layout>
  )
}

export default FAQPage
